import { IStudentClassSkillQuery } from "../models/studentClassSkill/query";
import { ICreateStudentClassSkill, IUpdateStudentClassSkill } from "../models/studentClassSkill/request";
import { IStudentClassSkill, IStudentClassSkillDetails } from "../models/studentClassSkill/response";
import IBaseListingResponse from "../utils/api/base-listing-response";
import { HttpClient, RequestParams } from "./http-client";

export class StudentClassSkillService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllStudentsClassSkill= (query: IStudentClassSkillQuery, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<IStudentClassSkill>>({
      path: `/student-class-skill`,
      method: "GET",
      query,
      secure: true,
      ...params,
    });

  getStudentClass = (id: number, params?: RequestParams) =>
    this.http.request<IStudentClassSkillDetails>({
      path: `/student-class-skill/${id}`,
      method: "GET",
      secure: true,
      ...params,
    });

  createStudentClass = (data: ICreateStudentClassSkill, params?: RequestParams) =>
    this.http.request<IStudentClassSkill>({
      path: "/student-class-skill",
      method: "POST",
      secure: true,
      body: data,
      ...params,
    });

  updateStudentClass = (
    id: number,
    data: IUpdateStudentClassSkill,
    params?: RequestParams
  ) =>
    this.http.request<IStudentClassSkill>({
      path: `/student-class-skill/${id}`,
      method: "PUT",
      body: data,
      secure: true,
      ...params,
    });

  deleteStudentClass = (id: number, params?: RequestParams) =>
    this.http.request<IStudentClassSkill>({
      path: `/student-class-skill/${id}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
}

export default StudentClassSkillService;
