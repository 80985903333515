import { IStudentClassQuery } from "../models/studentClass/query";
import { ICreateStudentClass, IUpdateStudentClass } from "../models/studentClass/request";
import { IStudentClass, IStudentClassDetails } from "../models/studentClass/response";
import { IStudentQuery } from "../models/students/query";
import { ICreateStudent, IUpdateStudent } from "../models/students/request";
import { IStudent, IStudentDetails } from "../models/students/response";
import IBaseListingResponse from "../utils/api/base-listing-response";
import { HttpClient, RequestParams } from "./http-client";

export class StudentClassService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllStudentsClass= (query: IStudentClassQuery, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<IStudentClass>>({
      path: `/student-class`,
      method: "GET",
      query,
      secure: true,
      ...params,
    });

  getStudentClass = (id: number, params?: RequestParams) =>
    this.http.request<IStudentClassDetails>({
      path: `/student-class/${id}`,
      method: "GET",
      secure: true,
      ...params,
    });

  createStudentClass = (data: ICreateStudentClass, params?: RequestParams) =>
    this.http.request<IStudentClass>({
      path: "/student-class",
      method: "POST",
      secure: true,
      body: data,
      ...params,
    });

  updateStudentClass = (
    id: number,
    data: IUpdateStudentClass,
    params?: RequestParams
  ) =>
    this.http.request<IStudentClass>({
      path: `/student-class/${id}`,
      method: "PUT",
      body: data,
      secure: true,
      ...params,
    });

  deleteStudentClass = (id: number, params?: RequestParams) =>
    this.http.request<IStudentClass>({
      path: `/student-class/${id}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
}

export default StudentClassService;
