import { IStudentMessageQuery } from "../models/studentsMessage/query";
import { ICreateStudentMessage, IUpdateStudentMessage } from "../models/studentsMessage/request";
import { IStudentMessage, IStudentMessageDetails } from "../models/studentsMessage/response";
import IBaseListingResponse from "../utils/api/base-listing-response";
import { HttpClient, RequestParams } from "./http-client";

export class StudentsMessageService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllStudentsMessages = (query: IStudentMessageQuery, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<IStudentMessage>>({
      path: `/messages`,
      method: "GET",
      query,
      secure: true,
      ...params,
    });

  getStudentMessages = (id: number, params?: RequestParams) =>
    this.http.request<IStudentMessageDetails>({
      path: `/messages/${id}`,
      method: "GET",
      secure: true,
      ...params,
    });

  createStudentMessages = (data: ICreateStudentMessage, params?: RequestParams) =>
    this.http.request<IStudentMessage>({
      path: "/messages",
      method: "POST",
      secure: true,
      body: data,
      ...params,
    });

  updateStudentMessages = (id: number, data: IUpdateStudentMessage, params?: RequestParams) =>
    this.http.request<IStudentMessage>({
      path: `/messages/${id}`,
      method: "PUT",
      body: data,
      secure: true,
      ...params,
    });

  deleteStudentMessages = (id: number, params?: RequestParams) =>
    this.http.request<IStudentMessage>({
      path: `/messages/${id}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
}

export default StudentsMessageService;
