import { IQuestionQuery } from "../models/question/query";
import { ICreateQuestion, IUpdateQuestion } from "../models/question/request";
import { IQuestion, IQuestionDetails } from "../models/question/response";
import IBaseListingResponse from "../utils/api/base-listing-response";
import { HttpClient, RequestParams } from "./http-client";


export class QuestionsService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllQuestion = (query: IQuestionQuery, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<IQuestion>>({
      path: `/faqs`,
      method: "GET",
      query,
      secure: true,
      ...params,
    });

  getQuestion = (id: number, params?: RequestParams) =>
    this.http.request<IQuestionDetails>({
      path: `/faqs/${id}`,
      method: "GET",
      secure: true,
      ...params,
    });

  createQuestion = (data: ICreateQuestion, params?: RequestParams) =>
    this.http.request<IQuestion>({
      path: "/faqs",
      method: "POST",
      body: data,
      secure: true,
      ...params,
    });

  updateQuestion = (
    id: number,
    data: IUpdateQuestion,
    params?: RequestParams
  ) =>
    this.http.request<IQuestion>({
      path: `/faqs/${id}`,
      method: "PUT",
      secure: true,
      body: data,
      ...params,
    });

  deleteQuestion = (id: number, params?: RequestParams) =>
    this.http.request<IQuestion>({
      path: `/faqs/${id}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
}

export default QuestionsService;
