import { IEvaluationLevelQuery } from "../models/evaluationLevels/query";
import { ICreateEvaluationLevel, IUpdateEvaluationLevel } from "../models/evaluationLevels/request";
import { IEvaluationLevel, IEvaluationLevelDetails } from "../models/evaluationLevels/response";
import IBaseListingResponse from "../utils/api/base-listing-response";
import { HttpClient, RequestParams } from "./http-client";

export class EvaluationLevelService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllEvaluationLevels = (query: IEvaluationLevelQuery, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<IEvaluationLevel>>({
      path: `/evaluation-levels`,
      method: "GET",
      query,
      secure: true,
      ...params,
    });

  getEvaluationLevel = (id: number, params?: RequestParams) =>
    this.http.request<IEvaluationLevelDetails>({
      path: `/evaluation-levels/${id}`,
      method: "GET",
      secure: true,
      ...params,
    });

  createEvaluationLevel = (data: ICreateEvaluationLevel, params?: RequestParams) =>
    this.http.request<IEvaluationLevel>({
      path: "/evaluation-levels",
      method: "POST",
      secure: true,
      body: data,
      ...params,
    });

  updateEvaluationLevel = (
    id: number,
    data: IUpdateEvaluationLevel,
    params?: RequestParams
  ) =>
    this.http.request<IEvaluationLevel>({
      path: `/evaluation-levels/${id}`,
      method: "PUT",
      body: data,
      secure: true,
      ...params,
    });

  deleteEvaluationLevel= (id: number, params?: RequestParams) =>
    this.http.request<IEvaluationLevel>({
      path: `/evaluation-levels/${id}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
}

export default EvaluationLevelService;
