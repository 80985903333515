import { ICreateUser, IUpdateUser } from "../models/users/request";
import { IUser, IUserDetails } from "../models/users/response";
import IBaseListingResponse from "../utils/api/base-listing-response";
import { HttpClient, RequestParams } from "./http-client";

export class EmployeeService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllEmployees = (query: any, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<IUser>>({
      path: `/employee`,
      method: "GET",
      query,
      secure: true,
      ...params,
    });

  getEmployee = (id: number, query?: any, params?: RequestParams) =>
    this.http.request<any>({
      path: `/employee/${id}`,
      method: "GET",
      query,
      secure: true,
      ...params,
    });

  updateEmployee = (id: number, data: IUpdateUser, params?: RequestParams) =>
    this.http.request<IUser>({
      path: `/employee/${id}`,
      method: "PUT",
      body: data,
      secure: true,
      ...params,
    });

  createEmployee = (data: ICreateUser, params?: RequestParams) =>
    this.http.request<IUser>({
      path: `/employee`,
      method: "POST",
      body: data,
      secure: true,
      ...params,
    });

  deleteEmployee = (id: number, params?: RequestParams) =>
    this.http.request<IUser>({
      path: `/employee/${id}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
}

export default EmployeeService;
