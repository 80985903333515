import { IQualificationsUserQuery } from "../models/qualificationsUser/query";
import {
  ICreateQualificationsUser,
  IUpdateQualificationsUser,
} from "../models/qualificationsUser/request";
import {
  IQualificationsUser,
  IQualificationsUserDetails,
} from "../models/qualificationsUser/response";
import { IRequestUserSchool } from "../models/users/request";
import { IResponseUserSchool } from "../models/users/response";
import IBaseListingResponse from "../utils/api/base-listing-response";
import { HttpClient, RequestParams } from "./http-client";

export class SchoolsUserService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllSchoolsUser = (
    query: IQualificationsUserQuery,
    params?: RequestParams
  ) =>
    this.http.request<IBaseListingResponse<IResponseUserSchool>>({
      path: `/get-user-school`,
      method: "GET",
      query,
      secure: true,
      ...params,
    });

  createSchoolsUser = (
    data: IRequestUserSchool,
    pathParams: { userId: number; schoolId: number },
    params?: RequestParams
  ) =>
    this.http.request<IResponseUserSchool>({
      path: `/users/${pathParams.userId}/schools/${pathParams.schoolId}`,
      method: "POST",
      secure: true,
      body: data,
      ...params,
    });

  updateSchools = (
    pathParams: { userId: number; schoolId: number },
    data: IRequestUserSchool,
    params?: RequestParams
  ) =>
    this.http.request<IResponseUserSchool>({
      path: `/users/${pathParams.userId}/schools/${pathParams.schoolId}`,
      method: "PUT",
      body: data,
      secure: true,
      ...params,
    });

  deleteSchoolsUser = (
    pathParams: { userId: number; schoolId: number },
    params?: RequestParams
  ) =>
    this.http.request<IResponseUserSchool>({
      path: `/users/${pathParams.userId}/schools/${pathParams.schoolId}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
}

export default SchoolsUserService;
