import { ISkillQuery } from "../models/skills/query";
import { ISkill, ISkillDetails } from "../models/skills/response";
import IBaseListingResponse from "../utils/api/base-listing-response";
import { HttpClient, RequestParams } from "./http-client";

export class RolesService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllRoles = (query: ISkillQuery, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<ISkill>>({
      path: `/roles`,
      method: "GET",
      query,
      secure: true,
      ...params,
    });

  getRole = (id: number, params?: RequestParams) =>
    this.http.request<ISkillDetails>({
      path: `/roles/${id}`,
      method: "GET",
      secure: true,
      ...params,
    });
}

export default RolesService;
